import React from "react"
import { Link, graphql } from "gatsby"

import Layout, { zeusTheme } from "../../components/layout"
import ZeusModules from "../../components/zeusModules"
import styles from "./index.module.scss"
import OpeningsComponent from "../../components/breezy-openings"

export const query = graphql`
  query {
    craft {
      page: entry(section: "careersIndex") {
        title
        slug
        id
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_careersIndex_careersIndex_Entry {
          alternateTitle
          body
          zeusModules {
            ...ZeusModules
          }
        }
      }
    }
  }
`

const CareersIndexPage = ({ data }) => {
  const page = data.craft.page
  const seo = data.craft.page.seomatic
  let title = page.alternateTitle != null ? page.alternateTitle : page.title

  return (
    <Layout seo={seo} theme={zeusTheme}>
      <div className="page-content-module">
        <div className="wrapper">
          <div className="mb-8">
            <div className="columns no-vertical-gap is-desktop">
              <div className="column is-7-desktop is-offset-1-desktop">
                <h1
                  className="mb-1 h-1 h--cms-title"
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
              </div>
            </div>
            <div className="columns no-vertical-gap is-tablet">
              <div className="column is-7-tablet is-6-desktop is-offset-1-desktop">
                <div
                  className="user-content"
                  dangerouslySetInnerHTML={{
                    __html: page.body,
                  }}
                />
              </div>
            </div>
            <OpeningsComponent></OpeningsComponent>
          </div>
        </div>

        {/*{page.zeusModules && <ZeusModules data={page.zeusModules} />}*/}

        {/*<div className="wrapper mb-12">*/}
        {/*  <div className="columns no-vertical-gap is-desktop">*/}
        {/*    <div className="column is-11-desktop is-offset-1-desktop">*/}
        {/*      {*/}
        {/*        getCurrentOpeningsHeader(careers)*/}
        {/*      }*/}
        {/*      <ul className={styles.jobs}>*/}
        {/*        {careers.map(career => {*/}
        {/*          return (*/}
        {/*            <li className={styles.jobsJob} key={`career-${career.id}`}>*/}
        {/*              <h3 className="mb-2 body-large">{career.title}</h3>*/}
        {/*              <p className="mb-3">{career.jobSummary}</p>*/}
        {/*              <Link*/}
        {/*                className="button button--small"*/}
        {/*                to={`/${career.uri}`}*/}
        {/*              >*/}
        {/*                Learn more*/}
        {/*              </Link>*/}
        {/*            </li>*/}
        {/*          )*/}
        {/*        })}*/}
        {/*      </ul>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </Layout>
  )
}

export default CareersIndexPage
